import React from "react"
import i18next from '../i18n/config';


import { Link, Trans, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import ImgCustomIco from "../img/L_twoj_produkt_4/marka_wlasna.png";
import ImgFinishIco from "../img/L_twoj_produkt_4/gotowy_produkt.png";


export default function Header(props) {



    return (
            <React.Fragment>
                <section id="ue" class="collaboration ourcoffees firstSection">
                    <h1>{i18next.t('CollaborationH1')}</h1>
                    <p>{i18next.t('CollaborationP')}</p>

                    <div class="icons">
                        <Link to={'/Collaboration/CustomProducts'}>
                        <div class="icon">
                            <div><img loading="lazy" src={ImgCustomIco} alt="Instanta Custom Products" /></div>
                            <p>{i18next.t('CollaborationCustomProduct')}</p>

                        </div>
                        </Link>

                        <Link to={'/Collaboration/FinishedProducts'}>
                        <div class="icon">
                            <div><img loading="lazy" src={ImgFinishIco} alt="Instanta Finish Products" /></div>
                            <p>{i18next.t('CollaborationFinishProduct')}</p>
                        </div>
                        </Link>
                    </div>

                </section>


            </React.Fragment>

            )


}